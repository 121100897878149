<script setup>
import {useRouter} from "vue-router";

const router = useRouter()

function show_first(){
  router.push({
    path: '/detail1',
  })
}
function show_second(){
  router.push({
    path: '/detail2',
  })
}
function show_third(){
  router.push({
    path: '/detail3',
  })
}
</script>

<template>
  <body class="page-body black">
  <div class="topnav">
    <a class="active" href="">AI音乐分析</a>
  </div>
  <div class="row">
    <div class="column">
<!--      onclick="window.open('https://www.xitongzhijia.net/soft/175423.html', '_blank');"-->
      <div class="card" @click="show_first">
        <img src="../assets/App-logo.svg" alt="Avatar" style="width:80px; height: 80px;">
        <h3>Engine Prime V1.1.0</h3>
        <p style="font-size: 12px">Engine Prime是一款十分优秀出色的音乐分析软件，界面清爽美观。</p>
      </div>

    </div>

    <div class="column">
<!--      onclick="window.open('https://ffmpeg.org/download.html#build-windows', '_blank');"-->
      <div class="card" @click="show_second">
        <img src="../assets/App-logo.svg" alt="Avatar" style="width:80px; height: 80px;">
        <h3>音乐下载软件工具</h3>
        <p style="font-size: 12px">FFmpeg是一套可以用来记录、转换数字音频、视频，并能将其转化为流的开源计算机程序。</p>
      </div>
    </div>

    <div class="column">
<!--      onclick="window.open('https://songtell.net/', '_blank');"-->
      <div class="card"  @click="show_third">
        <img src="../assets/App-logo.svg" alt="Avatar" style="width:80px; height: 80px;">
        <h3>AI歌词鉴赏</h3>
        <p style="font-size: 12px">一个非常有趣的AI歌曲鉴赏网站，拥有丰富多元化的歌曲鉴赏库。</p>
      </div>
    </div>

  </div>

  </body>
</template>

<style scoped>
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  overflow: hidden;
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  background-color: #f1f1f1;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #FF0000;
  color: white;
}
/* Float four columns side by side */
.column {
  float: left;
  width: 20%;
  height: 30%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 60px  20px 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}
.card:hover{
  background-color:#CCC;
}
</style>