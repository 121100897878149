<template>
  <div>
    <h1 style="text-align: center;">灵听用户服务协议</h1>
    <p>更新日期：2024年6月4日</p>
    <h3>1.特别提醒</h3>
    <p>1.1.在您开始阅读并决定是否接受《灵听用户服务协议》（以下称“本协议”）之前，灵听特别提醒您应留意本协议中加粗形式的条款内容。</p>
    <p>1.2.除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您对本服务的登录、查看、下载、发布信息、使用等行为即视为您已阅读并同意受本协议的约束。</p>
    <p>1.3.若您是18周岁以下的未成年人，在使用灵听服务前，请您务必先取得您家长或法定监护人的书面同意，并在监护人在场的情况进行使用本协议所述的服务。</p>
    <p>1.4.本协议各条款的标题仅为方便检索使用，部分标题下的条款内容可能将作为独立的法律文件、相关业务规则（以下统称“单独协议”）供您阅读并遵守。</p>
    <h3>2.定义解释</h3>
    <p>2.1本协议中的我们是指“灵听”或 APP“灵听”</p>
    <p>2.2.本协议中的“用户”指在阅读本协议后选择接受本协议全部条款的个人使用者。用户可通过注册、登录灵听帐号或选择其他第三方登录的方式使用服务，但是否注册、登录灵听帐号或选择其他第三方登录的方式不影响其作为本协议项下的用户身份。</p>
    <p>2.3.本协议中的“服务”指软件、相关网站以及灵听提供的其他服务。</p>
    <p>2.4.本协议中的“软件”指灵听提供的包括但不限于移动客户端，用户通过移动客户端等进行搜索、试听、下载及管理等。</p>
    <p>2.5.本协议中的“信息内容”将可能包括但不限于任何在服务中及软件上的资料、文字、软件、音频、图片、视频、图表、广告以及其他资料等。</p>
    <h3>3.用户帐号规则</h3>
    <p>3.1.为更好地使用本协议项下的服务，用户可注册灵听帐号，并在注册帐号后自行妥善保管帐号及密码，对通过该帐号所进行的和与之相关的一切行为负责。</p>
    <p>
      3.2.用户在注册灵听帐号时，应按照注册提示真实、完整地填写相关注册信息，并及时更新该等注册信息，保证该等注册信息自帐号注册起至帐号注销止的期间内始终处于真实、有效状态，且不得存在错误、虚假、违法的内容。因用户填写的注册信息存在错误、虚假、违法等情形导致的后果，无论是否公开，均由该用户自行承担。</p>
    <p>
      3.3.为了便于用户管理，当用户选择其他第三方登录的方式时，灵听已经为用户配置第三方登录方式相对应的灵听帐号。该帐号将无需由用户填写，但是根据国家法律规定，灵听有权要求用户在填写真实、完整地注册信息后方可以继续使用服务。</p>
    <p>
      3.4.无论用户选择何种帐号注册方式，用户帐号的所有权属于灵听，用户完成申请注册手续后，仅获得帐号的使用权，且该使用权仅属于初始申请注册人。初始申请注册人不得赠与、借用、租用、转让或售卖帐号或者以其他方式许可非初始申请注册人使用帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用帐号。</p>
    <p>3.5.用户有责任妥善保管帐户信息及帐户密码的安全，用户需要对注册帐户以及密码下的行为承担法律责任。用户同意在任何情况下不向他人透露帐户及密码信息。当在用户怀疑他人在使用自己帐号及密码时，应立即通知灵听。</p>
    <p>3.6.用户如果长期不登录帐号、在多台终端设备上同时使用、显示、运行同一帐号或涉嫌违反灵听本协议及单独协议的有关规定，灵听有权回收该帐号，由此带来的任何损失均由用户自行承担。</p>
    <h3>4.隐私保护</h3>
    <p>4.1.在使用灵听服务过程中，灵听对用户相关信息采用专业加密存储与传输方式，并运用各种安全技术和程序建立完善的管理制度来保护用户隐私信息，以免遭受未经授权的访问、使用或披露。灵听也可能通过技术手段采集、分析用户使用服务的行为习惯信息，无论何种方式灵听都尊重并保护的用户隐私信息。</p>
    <p>4.2.依据国家法律法规规定的“后台实名、前台自愿”原则，灵听将对用户进行基于移动电话号码等方式进行真实身份信息认证。</p>
    <p>4.3.除司法机关或其他有权机关依法要求提交该等用户隐私信息外，未经用户的同意，灵听将不会擅自披露、改变或其他方式使用用户隐私信息。若用户认为灵听存在违反前述范围使用该等隐私信息的，应立即与灵听进行联系，灵听将及时核实处理。</p>
    <p>4.4.灵听的详细隐私政策请见《灵听用户隐私政策》</p>
    <h3>5.灵听的服务形式</h3>
    <p>5.1.灵听软件服务形式</p>
    <p>5.1.1灵听可能为不同的终端设备开发不同的软件版本，用户应当根据实际需要选择下载合适的版本进行安装。</p>
    <p>5.1.2如果用户从未经合法授权的第三方获取软件或与软件名称相同的安装程序，灵听将无法保证软件能否正常使用，并对因此给用户造成的任何损失不予负责。</p>
    <p>5.1.3用户同意软件仅为用户个人非商业性质的使用。用户不得为商业运营目的安装、使用、运行本软件。</p>
    <p>
      5.1.4为了增进用户体验、完善服务内容，灵听将不时提供软件更新服务，该更新可能会采取软件替换、修改、功能强化、版本升级等形式。软件新版本发布后，旧版软件可能无法使用。灵听不保证旧版软件继续可用及相应的客户服务，请用户及时下载最新版本。</p>
    <p>5.1.5除非法律允许或灵听书面许可，用户不得从事下列行为，否则，灵听将保留依法追究法律责任的权利:</p>
    <p>（1）删除软件及其副本上关于著作权的信息；</p>
    <p>（2）对软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；</p>
    <p>（3) 对灵听拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等、擅自借助本软件及相关信息发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；</p>
    <p>
      （4）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；</p>
    <p>（5）修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；</p>
    <p>（6）通过非灵听开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具；</p>
    <p>
      （7）进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本软件系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；</p>
    <p>（8）通过修改或伪造软件作品运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，及将用于上述用途的软件通过信息网络向公众传播或者运营；</p>
    <p>（9）其他未经灵听明示授权的行为。</p>
    <p>5.2.灵听的收费服务形式</p>
    <p>5.2.1灵听的内容目前均为免费，但这不代表您可以下载进行二次传播或二次使用，您从灵听或APP下载的所有内容仅供个人学习使用，如有其他用途请联系内容作者申请授权，一切因版权引起的纠纷责任将由您承担。</p>
    <p>5.3.灵听广告服务形式</p>
    <p>5.3.1用户同意灵听可以在提供服务的过程中自行或由第三方广告商向用户发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向用户特别通知而变更。</p>
    <p>5.3.2灵听为用户提供选择关闭广告信息的功能，但任何时候用户都不得以本协议未明确约定或灵听未书面许可的方式屏蔽、过滤广告信息。</p>
    <p>5.3.3灵听依照法律的规定对广告商履行相关义务，用户应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，灵听不承担责任。</p>
    <h3>6.用户行为规范</h3>
    <p>6.1.用户在使用灵听服务时不得从事以下行为：</p>
    <p>（1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；</p>
    <p>（2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</p>
    <p>（3）使用任何包含有通过侵犯知识产权、商业机密或任何一方的其他合法权利的方式利用本服务获得的相应的音频、视频资料；</p>
    <p>（4）制作、发布、传播用于窃取他人帐号及他人专属信息、财产、保密信息的软件；</p>
    <p>（5）在未经灵听书面明确授权前提下，出售、出租、出借、散布、转移或转授权本软件和本服务或相关的链接或从使用软件和服务或软件和服务的条款中获利，无论以上使用是否为直接经济或金钱收益；</p>
    <p>（6）未经灵听内容作者允许，擅自将本“软件”、本服务的音乐内容资源库中的任何音乐资源应用于商业用途或进行营利活动；</p>
    <p>（7）虚构事实、隐瞒真相以误导、欺骗他人；</p>
    <p>（8）发表、传送、传播垃圾信息；</p>
    <p>（9）以其他以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件和本服务；</p>
    <p>（10）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
    <p>
      6.2.用户在使用灵听服务时，如应严格遵循上述约定，因用户不当使用灵听服务而造成的其自己、灵听或其他第三方的损失，均应由该用户承担。同时，用户应自行判断信息内容的真实性、合法性和有效性，并自行承担使用该等信息内容可能产生的一切后果与责任。</p>
    <h3>7.未成年人使用条款</h3>
    <p>7.1.未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：</p>
    <p>（1）认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；</p>
    <p>（2）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；</p>
    <p>（3）在监护人或老师的指导下，学习正确使用网络；</p>
    <p>（4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。</p>
    <p>7.2.监护人、学校均应对未成年人使用本服务时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。</p>
    <h3>8.不可抗力及其他免责事由</h3>
    <p>8.1.用户理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，灵听将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户造成的损失灵听在法律允许的范围内免责。</p>
    <p>8.2.在法律允许的范围内，灵听对以下情形导致的服务中断或受阻不承担责任：</p>
    <p>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
    <p>（2）用户或灵听的电脑软件、系统、硬件和通信线路出现故障；</p>
    <p>（3）用户操作不当；</p>
    <p>（4）用户通过非灵听授权的方式使用本服务。</p>
    <p>8.3.用户理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，灵听不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：</p>
    <p>（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</p>
    <p>（2）因使用本服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；</p>
    <p>（3）其他因网络信息或用户行为引起的风险。</p>
    <h3>9.知识产权保护及声明</h3>
    <p>9.1灵听在软件、产品及相关服务中提供的包括但不限于软件、技术、程序、网页、文字、图片、歌谱、音频、视频、歌曲列表、版面设计、电子文档、图标、硬件产品等著作权、商标权、专利权、商业秘密等知识产权属于灵听所有。</p>
    <p>9.2
      未经灵听或相关权利人书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方对“灵听”、“灵听app”和“灵听软件”在内的任何商标、商号、域名或其他显著品牌特征等实施展示、使用、转让或申请注册商标、域名注册等行为。</p>
    <p>9.3
      用户在使用灵听软件、产品及相关服务时发布的信息内容（包括但不限于个人简介、文档、评论，专辑封面、图片、照片、歌单，以类似摄制电影的方法创作的作品、录音录像制品，音乐效果等文字、图像、软件等）均由用户原创或已获合法授权（且含转授权）。用户通过灵听发布的信息内容的知识产权归属用户或原始权利人所有，且用户应对发布的信息内容承担责任。</p>
    <p>9.4
      用户对其发布的信息内容（包括但不限于个人简介、文档、评论，专辑封面、图片、歌谱、照片、歌单，以类似摄制电影的方法创作的作品、录音录像制品，音乐效果等文字、图像、软件等）授予灵听在全球范围内、免费、非独家、可多次再许可，以复制、翻译、修改、汇编、改编、编辑、传播、出版、制作衍生品、表演和展示等方式使用的权利，使用范围包括但不限于灵听及再许可的网站、应用程序、硬件产品等。上述授予的权利包括在与灵听软件、产品及相关服务、灵听品牌有关的任何宣传、推广、广告、营销和/或研究中使用。无论灵听是否基于用户发布的信息内容获得利益，除非双方另行有书面约定，灵听均无需向用户支付任何费用。</p>
    <p>9.5 如用户发现灵听音乐上有内容侵犯您的知识产权或合法权益时，可联系我们向我们投诉，要求删除侵权内容或者断开侵权内容的链接。我们将在核实投诉情况后酌情处理。</p>
    <h3>10.违约责任</h3>
    <p>10.1.如果灵听发现或收到他人举报或投诉用户违反本协议约定的，灵听有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚。用户对此不持异议。</p>
    <p>10.2.灵听依据上述约定获得处理违法违规内容的权利，该权利不构成灵听的义务或承诺，灵听不能保证及时发现违法行为或进行相应处理。</p>
    <p>10.3.用户理解并同意，灵听有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</p>
    <p>10.4.用户理解并同意，因用户违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，用户应当独立承担责任；灵听因此遭受损失的，用户也应当一并赔偿。</p>
    <h3>11.协议的生效及终止</h3>
    <p>11.1灵听有权在必要时修改本协议条款。用户可以在相关服务页面查阅最新版本的协议条款。</p>
    <p>11.2.本协议条款变更后，如果您继续使用灵听提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用灵听提供的软件或服务。</p>
    <p>11.3.灵听可能会对服务内容进行变更，也可能会中断、中止或终止服务。</p>
    <p>11.4.灵听建议用户可采取合法的手段保存其所需要的信息内容和数据。如果用户的服务被终止，灵听可以从服务器上永久地删除用户的数据。服务终止后，灵听没有义务向用户返还数据。</p>
    <h3>12.其他</h3>
    <p>12.1.本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
    <p>12.3.若用户和灵听之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，用户同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</p>
    <p>12.4.本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
  </div>
</template>

<script>
export default {
  name: "TsService"
}
</script>

<style scoped>
</style>
