<template>
  <!--  <router-link to="/">HomePage</router-link>-->
  <router-view></router-view>
</template>
<script>
import {createRouter, createWebHistory} from 'vue-router';
import MainPage from './components/MainPage.vue';
import FirstPage from './components/FirstPage.vue';
import HomePage from './components/HomePage.vue';
import SecondPage from "@/components/SecondPage.vue";
import DownloadPage from "@/components/DownloadPage.vue";
import DetailPageOne from "@/components/DetailPageOne.vue";
import DetailPageTwo from "@/components/DetailPageTwo.vue";
import DetailPageThree from "@/components/DetailPageThree.vue";
import GZHDownloadPage from "@/components/GZHDownloadPage.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue"
import Sdk from "@/components/Sdk.vue";
import TsService from "@/components/TsService";
import FeedbackPage from "@/components/FeedbackPage";
import DownloadPage2 from "@/components/DownloadPage2.vue";

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: "/app/download",
    name: "App Download",
    component: DownloadPage
  },
  {
    path: "/app/dl",
    name: "App Down",
    component: DownloadPage2
  },
  {
    path: "/gonzhonghao/app/download",
    name: "App Download Gong Zhong Hao",
    component: GZHDownloadPage
  },
  {
    path: "/detail1",
    name: "Detail Page One",
    component: DetailPageOne
  },
  {
    path: "/detail2",
    name: "Detail Page Two",
    component: DetailPageTwo
  },
  {
    path: "/detail3",
    name: "Detail Page Three",
    component: DetailPageThree
  },
  {
    path: "/ts",
    name: "Privacy Policy",
    meta: {title: "灵听用户隐私政策"},
    component: PrivacyPolicy
  },
  {
    path: "/ts/sdk",
    name: "Sdk",
    meta: {title: "第三方SDK目录"},
    component: Sdk
  },
  {
    path: "/ts-service",
    name: "Ts Service",
    meta: {title: "灵听用户服务协议"},
    component: TsService
  },
  {
    path: "/feedback",
    name: "Feedback Page",
    meta: {title: "意见反馈"},
    component: FeedbackPage
  },
  {
    path: '/home',
    name: "Home",
    component: HomePage,
    children: [
      {path: '/home', component: MainPage},
      {path: '/first', component: FirstPage},
      {path: '/second', component: SecondPage},
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
})
export default router;
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.topnav {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #FF0000;
  color: white;
}
</style>
