<template>
  <h1 style="text-align: center;">灵听用户隐私政策</h1>
  <p>更新日期：2024年5月29日</p>
  <p>欢迎您使用灵听的产品和服务。灵听由广州市禾唱网络技术有限责任公司提供。 灵听（以下简称“我们”）深知个人信息对您的 重要性，并将会尽全力保护您的个人信息安全可靠。
    在您使用我们的产品或服务前，请您仔细阅读并充分理解以下条款，特别提醒您应留意本隐私政策中加粗形式的条款内容。如果您不同意本隐私政策，您可能无法正常使用我们的产品、服务。</p>
  <p>我们的所有产品和服务均适用本隐私政策，但如该产品或服务单独设置了隐私条款或隐私协议，则单独的隐私条款或隐私协议优先适用。</p>
  <p>
    请您注意，本隐私政策仅适用于我们所收集的您的个人信息，并不适用于任何第三方对您的个人信息的收集，以及任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方收集、储存和使用的您个人信息的行为在法律允许的范围内亦不承担任何责任。因此，在使用第三方服务时请您留意和仔细阅读第三方向您展示的相关用户协议和隐私政策，并妥善保管和谨慎提供您的个人信息。</p>
  <p><strong>本隐私政策主要向您说明如下信息：</strong></p>
  <p>
    <a href="#t1">
      <strong>1.我们如何收集和使用您的个人信息</strong>
    </a>
  </p>
  <p>
    <a href="#t2">
      <strong>2.我们如何使用Cookie以及同类技术</strong>
    </a>
  </p>
  <p>
    <a href="#t3">
      <strong>3.第三方SDK及API技术</strong>
    </a>
  </p>
  <p>
    <a href="#t4">
      <strong>4.我们如何共享、转让和公开披露您的个人信息</strong>
    </a>
  </p>
  <p>
    <a href="#t5">
      <strong>5.您如何了解和控制自己的个人信息</strong>
    </a>
  </p>
  <p>
    <a href="#t6">
      <strong>6.我们如何存储和保护您的个人信息</strong>
    </a>
  </p>
  <p>
    <a href="#t7">
      <strong>7.我们如何保护未成年人的个人信息</strong>
    </a>
  </p>
  <p>
    <a href="#t8">
      <strong>8.本隐私政策如何更新</strong>
    </a>
  </p>
  <p>
    <a href="#t9">
      <strong>9.如何与我们联系</strong>
    </a>
  </p>
  <h3 id="t1">1. 我们如何收集和使用您的个人信息</h3>
  <p>1.1 您在使用我们的产品或服务时，我们需要/可能需要收集和使用您的一些个人信息：对于使用我们产品或服务的核心业务功能所必需的信息，为了保障产品或服务的正常运行，您须授权我们收集和使用；
    如您拒绝提供，您将无法正常使用我们的产品或服务。对于使用我们产品或服务的附加业务功能可能需要收集的信息，您可以选择是否授权我们收集；如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。</p>
  <p>1.2 您需授权我们收集和使用您的个人信息的功能场景包括：</p>
  <p>1.2.1 服务内容展示/浏览/播放/下载功能</p>
  <p>我们的产品或服务为您提供音频、音乐视频等服务内容的展示、浏览、播放和下载功能，在此过程中，我们会根据您使用我们产品或服务的具体操作收集您的一些信息，包括如下个人信息：</p>
  <p>(1)
    设备信息：包括设备MAC地址、唯一设备识别码、登录IP地址、设备型号、设备参数、设备名称、设备标识、浏览器类型和设置、语言设置、操作系统和应用程序版本、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号、
    设备所在位置相关信息（包括您授权我们获取的地理位置信息）。
    为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限并根据您的授权获取相关信息。</p>
  <p>(2) 日志信息：当您使用我们的产品或服务时，我们会自动收集您的个人上网记录，并作为有关操作日志、服务日志保存，包括您的浏览记录、点赞、分享、评论、互动记录、收藏、关注、下载记录、播放记录、播放时长、访问日期和时间记录。</p>
  <p>我们收集这些信息是为了向您提供我们最核心的服务内容展示/播放/下载服务，如您拒绝提供上述信息或权限将可能导致您无法使用我们的产品与服务。</p>
  <p>请您知悉，单独的设备信息、日志信息等无法识别您的身份信息。</p>
  <p>1.2.2 账号注册/登录功能</p>
  <p>(1) 当您使用账号注册功能时，我们会收集由您主动提供给我们的一些单独或者结合识别您实名身份的信息，包括：手机号码、电子邮箱、用户名、密码。您的密码将以加密形式进行自动存储、传输、验证，我们不会以明文方式存储、
    传输、验证您的密码。我们收集这些信息是用以完成注册程序、为您持续稳定提供专属于注册用户的产品或服务，并保护您的账号安全。您应知悉，手机号码和验证码匹配结果属于您的个人敏感信息，我们收集该类信息是为了满足相关法律法规的要求，如您拒绝提供可能导致您无法使用我们的此功能，请您谨慎考虑后再提供。</p>
  <p>(2) 同时，您也可以在注册时或后续使用过程中填写或补充您的头像、性别、地区、生日、职业、个性签名、兴趣爱好。这料将帮助我们更好地了解您
    并为您提供更优质的服务。</p>
  <p>(3) 如您使用第三方平台（例如微信）的账号登录我们的产品，我们将根据您的授权获取该第三方账号下的相关信息（包括：昵称、头像，具体以您的授权 内容为准）以及身份验证信息（个人敏感信息）。</p>
  <p>我们收集这些信息是用于为您提供账号登录服务以及保障您的账号安全，防范安全风险。如您拒绝授权此类信息的，您将无法使用第三方平台的账号登录我们平台，但不影响我们为您提供的其他产品和服务的正常使用。</p>
  <p>1.2.4 身份认证功能</p>
  <p>当您使用我们的产品或服务中的身份认证功能或服务时，为满足相关法律规定及监管要求、确保用户身份真实性、实现反欺诈等风险控制、保障系统和服务安全或提供服务之需要，我们将需要收集您的真实身份信息（可能包括姓名、
    身份证相关信息、静态或动态的面部特征信息、手机号码、声纹信息、性别、 年龄、星座、职业、介绍、学校、院系、入学年份、地址，前述信息均为个人 敏感信息）以完成实名认证，相关业务场景可能包括：
    注册产品时、使用信息发布（如发布专辑）等服务时、申请认证音乐人时、我们履行代扣代缴个人所得税的义务时以及其他需要进行身份认证的场景。为实现实名认证的目的，您同意我们可以自行或委托第三方向有关机构（如个人征信机构、政府机构等）提供、查询、核对您的前述身份信息。
    在您实名认证成功后，如无正当事由，实名信息将无法修改，如确需修改，请您与我们联系解决。</p>
  <p>1.2.5 点赞、评论以及图文或视频等信息制作、发布、交流互动功能</p>
  <p>当您在我们的部分产品或服务中使用视频剪辑创作、上传、发布、直播、社区
    发帖、平台内交流互动、点赞、评论、分享等服务/功能时，除注册登录账户之外，您可能会主动提供相关图文/视频内容、互动信息（包括但不限于帖子、点赞、评论、分享、交流互动信息）。
    我们会自动收集您的上述信息，并展示您的用户昵称、头像、发布的信息内容。请您知悉，您发布的信息中可能包含他人的个人信息，请您务必取得他人的合法授权，避免非法泄露他人的个人信息。如您不同意我们收集上述信息，您将无法使用我们的信息发布功能，但不影响您使用我们为您提供的其他产品和服务。</p>
  <p>1.2.6 购买服务</p>
  <p>(1) 当您在我们的产品或服务中使用赞赏服务或购买数字商品时，我们需要根据商品或服务类型收集如下部分或全部的个人信息，包括：交易商品或服务信息、交易金额、支付账号（前述信息均为个人敏感信息）、下单时间、订单商
    户、订单编号、订单状态、支付方式、支付状态。
    我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务等。</p>
  <p>(2) 如您使用虚拟币充值、虚拟物品购买赠送、会员权益开通服务，我们会收集您的充值、交易、余额信息。充值、交易、余额信息属于个人敏感信息，我们将使用加密技术收集、传输、存储。</p>
  <p>1.2.7 客服、其他用户响应功能</p>
  <p>当您与我们的客服互动时或使用其他用户响应功能时（包括：在线提交意见反馈、与在线/人工客服沟通、提出我们的产品或服务的售后申请、行使您的相 关个人信息控制权、其他客户投诉和需求），
    为了您的账号与系统安全，我们可能需要您先行提供账号信息，并与您之前的个人信息相匹配以验证您的用户
    身份。在您使用客服或其他用户响应功能时，我们可能还会需要收集您的如下个人敏感信息：联系方式（您与我们联系时使用的电话号码/电子邮箱/QQ号码或您向我们主动提供的其他联系方式）、
    您与我们的沟通信息（包括文字/ 图片/音视频 /通话记录形式）、与您需求相关联的其他必要信息。我们收集这 些信息是为了调查事实与帮助您解决问题，如您拒绝提供可能导致您无法使用我们的客服等用户响应机制。</p>
  <p>1.2.8 产品安全保障功能</p>
  <p>(1) 我们需要收集您的一些信息来保障您使用我们的产品或服务时的账号与系统 安全，并协助提升我们的产品服务的安全性和可靠性，以防产生任何危害用户、灵听、社会的行为，包括您的如下个人信息：登录IP地址、产品版本号、
    语言模式、浏览记录、网络使用习惯、服务故障信息，以及个人敏感信息：
    交易信息、实名认证信息。我们会根据上述信息来综合判断您账号、账户及交易风险、进行身份验证、客户服务、检测及防范安全事件、诈骗监测、存档和备 份用途，并依法采取必要的记录、审计、分析、处置措施。
    一旦我们检测出存
    在或疑似存在账号安全风险时，我们会使用相关信息进行安全验证与风险排除，确保我们向您提供的产品和服务的安全性，以用来保障您的权益不受侵害。同时，当发生账号或系统安全问题时，我们会收集这些信息来优化我们的产品和服务。</p>
  <p>(2)
    为确保您设备操作环境的安全以及提供我们的产品或服务所必需，防止恶意程序和反作弊，我们会在您同意本隐私政策后获取您设备上已安装或正在运行的必要的应用/软件列表信息（包括应用或软件来源、应用或软件总体运行情况、崩溃情况、使用频率等）。
    请您知悉，单独的应用或软件列表信息无法 识别您的特定身份。</p>
  <p>1.3 对于使用我们产品或服务的附加业务功能可能需要收集的信息，您可以选择是否授权我们收集；如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。
    这些信息一般是需要您配合开启相应的设备权限方可获取，因此您可以通过设备设置页面相应权限进行管理。同时，您也可以随时通过手机应用权限设置开启/取消该权限，例如：“手机android端App”的路径为：设置—应用和通知-应用权限-灵听。
    您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。
    您可自主选择提供个人信息的情形包括：</p>
  <p>1.3.1 基于设备存储/相册权限的附加业务功能</p>
  <p>当您上传/更新账号头像、图文/视频制作与发布、评论、发帖、播放本地音视 频、下载、设置歌词字体等业务功能时，我们将需要获取您的设备存储/相册权限，并收集您提供的文件、图片、视频内容信息（个人信息）。</p>
  <p>1.3.2 基于设备相机权限的附加业务功能</p>
  <p>当您使用设置或更新头像图片、相册管理、扫描图片、图像保存、发布图文/ 视频内容、上传歌单封面等业务功能时，我们将需要获取您的设备相机权限，并收集您提供的图片、视频内容信息（个人信息）。</p>
  <p>1.3.3 基于设备电话权限的附加业务功能</p>
  <p>当您在听音乐的过程中接到电话时，我们需要通过电话权限来帮助您暂停/继 续播放音乐。</p>
  <p>1.3.4 基于设备短信权限的附加业务功能</p>
  <p>当您使用手机验证码登录业务功能时，我们将需要读取您的短信权限，帮助您 自动输入验证码至登录表单。</p>
  <p>1.3.5 基于设备联系人权限的附加业务功能</p>
  <p>当您使用添加好友、邀请好友功能业务功能时，我们将需要获取您的设备联系 人权限，并收集相关联系人信息（包括联系人姓名及对应的联系方式、联系人
    好友列表）。通讯录信息属于个人敏感信息，请您谨慎开启联系人权限，我们亦会高度对待和谨慎处理您的联系人信息（包括高强度加密保护等）。</p>
  <p>1.3.6 基于位置权限的附加业务功能</p>
  <p>(1) 在附近的人等基于地理位置所提供的定位功能、在发布动态时使用定位功能，我们需要申请获取您的位置权限，并在您授权后收集您的位置信息（个人敏感信息），地理位置信息用于附近的人服务。</p>
  <p>(2) 基于歌曲版权方授权地域限制，我们会通过IP地址分析您的地理位置，若分析结果显示您位于中国大陆以外的国家或地区，我们需要申请获取您的位置权限，并在您授权后收集您的位置信息（个人敏感信息）以判断您是否在版权授权区域内。
    地理位置信息是个人敏感信息，如您不提供将可能无法获得歌曲播放功能，但不影响您使用我们的其他服务。</p>
  <p>1.4 其他用户分享的信息中含有您的信息及我们的使用方式</p>
  <p>如其他用户发布的评论、回复、照片、音频、视频中可能包含您的信息。我们将无法修改其他用户的信息，如实展示可能包含您的信息。如您认为侵犯您的个人信息，请您通过下述第8条投诉方式和联系方式联系我们进行处理。</p>
  <p>1.5 为保障服务正常运行的收集的信息</p>
  <p>在您使用我们服务过程中，为识别应用异常状态，了解产品适配性，以保障服务正常运行，我们可能直接或间接地收集，存储关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括:</p>
  <p>(1)
    日志信息：当您使用我们的服务时，我们可能会自动收集（包括后台运行状态下收集），您对我们服务的详细使用情况，作为有关网络日志保存。包括您的登录账号，搜索查询内容，IP地址，浏览器的类型，电信运营商，网络环境，使用的语言，访问日期和时间，停留时间，关注，订阅，收藏及分享。</p>
  <p>(2) 设备信息：我们可能会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息，包括设备机型，设备序列号，操作系统及版本，客户端版本，设备分辨率，包名，设备设置，设备标识符
    （MAC地址/IMEI/IDFV/Android ID/OPENUDID/GUID/MAC/OAID/IDFA/SIM卡IMSI信息），App自身运行进程信息，设备所在位置相关信息（例如IP地址，GPS位置信息），设备传感器，软硬件特征信息，网络类型和状态。
    为了收集上述个人设备信息，我们将会申请访问您设备的相关权限，包括设备信息权限，位置权限等。</p>
  <p>1.6 需要运行应用自启动或关联启动行为的场景</p>
  <p>为了实现稳定的而流畅的音乐播放体验，我们的应用需要在自启动的同时启动第三方音乐播放服务，和前台常驻通知栏。</p>
  <p>(1) 目的：提升用户体验：确保乐音播放的流畅。</p>
  <p>(2) 透明化：我们承诺，所有涉及自启动或关联启动的行为都将遵循透明原则，事先征得您的同意，并在应用设置中提供管理选项。</p>
  <p>(3) 最小权限原则：仅在必要时启用，且仅请求完成服务所必需的最低权限。</p>
  <p>(4) 用户可控：您随时可以在设备的应用管理中调整应用的自启动权限，根据个人需求开启或关闭。</p>
  <p>(5) 必要性：应用的自启动与关联启动虽然可能涉及一定的系统资源占用，但对于保证您享受到即时、高效的服务体验是至关重要的。通过合理的管理与优化，我们旨在达到服务与效率的最佳平衡，同时也尊重并保护您的隐私权益。</p>
  <p>1.7 例外情形</p>
  <p>您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意：</p>
  <p>(1) 与我们履行法律法规规定的义务相关的；</p>
  <p>(2) 与国家安全、国防安全直接相关的；</p>
  <p>(3) 与公共安全、公共卫生、重大公共利益直接相关的；</p>
  <p>(4) 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
  <p>(5) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
  <p>(6) 所收集的信息是您自行向社会公开的或者是从合法公开的渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；</p>
  <p>(7) 根据与您签订和履行相关协议或其他书面文件所必需的；</p>
  <p>(8) 用于维护我们的产品或服务的安全稳定运行所必需的，例如：发现、处置 产品或服务的故障；</p>
  <p>(9) 有权机关的要求、法律法规等规定的其他情形。</p>
  <p>1.8 请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功 能或服务未在前述说明中且收集了您的个人信息，我们会通过更新本隐私政
    策、启动或浏览界面提示、弹窗、网站公告、站内信、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，
    并为您提供自主选择同意的方式，且在征得您同意后收集。在此过程中，如果您对相关事宜有任何疑惑的，可以通过本隐私政策第8条提供的方式联系我们，我们会尽快为您作出解答。</p>
  <h3 id="t2">2. 我们如何使用Cookie以及同类技术</h3>
  <p>Cookie 和同类技术是互联网中普遍使用的技术。当您使用灵听及相关服务时，我们可能会使用相关技术收集您的信息。我们使用Cookie和同类技术主 要为了实现以下功能或服务：</p>
  <p>2.1 保障产品与服务的安全、高效运转：我们可能会设置认证与保障安全性的Cookie或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、 欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</p>
  <p>2.2 帮助您获得更轻松的访问体验：使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（例如：表单填写）。</p>
  <h3 id="t3">3. 第三方SDK及API技术</h3>
  <p>部分功能和/或服务需要使用第三方SDK（或API）技术。请您知悉，这些第三方SDK（或API）可能会收集或使用您的部分个人信息（具体请见
    <router-link to="/ts/sdk">第三方SDK目录</router-link>
    ）。
    我们会对相关SDK（或API）进行严格的安全检测，并采取技术手段，努力确保其按照本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
    另外，对我们与之共享用户信息的公司、组织和个人，我们也会与其签署协议来规范对于用户信息的使用。
  </p>
  <h3 id="t4">4. 我们如何共享、转让和公开披露您的个人信息</h3>
  <p>4.1 共享</p>
  <p>我们不会与灵听服务提供者以外的公司、组织和个人共享您的个人信息，但以下情形除外：</p>
  <p>4.1.1 在法定情况下的共享：我们可能会根据法律法规规定、诉讼、争议解决需 要，或按行政、司法机关依法提出的要求，向相关行政、司法机关共享您的个人信息。</p>
  <p>4.1.2 在获取明确同意的情况下共享：获得您或/和您的父母或监护人同意后，我们会与其他方共享第三方提供您的个人信息。</p>
  <p>4.1.3灵听关联公司间共享：为便于我们基于灵听统一的帐号体系向您提供一致服务、识别帐号异常、保护灵听及用户或公众的人身财产安全 免遭侵害，以及便于您进行统一管理，您知悉并同意，您的相关个人信息可能
    会在灵听产品和服务内部进行必要共享。我们会在您使用灵听帐号登录灵听产品和服务时共享您的帐号信息。 如果我们需要共享您的个人敏感信息或需要改变个人信息的使用及处理目的时，我们会将再次征求您的授权同意。</p>
  <p>4.1.4 与业务合作伙伴共享：您理解并同意，为了必要/合理的业务的开展，履 行我们在相关用户协议或本隐私政策中的义务，行使我们的权利或遵守法律规
    定等目的，我们可能需要向部分业务合作伙伴共享您的部分信息。但我们承诺我们只会共享您的必要个人信息，
    如果我们共享您的个人敏感信息或改变个人 信息的使用及处理目的，我们会将再次征求您的授权同意。特别地，我们所提 供的产品和服务中某些具体的模块或功能可能由业务合作伙伴的应用程序接口
    （API）、业务合作伙伴软件工具开发包（SDK）提供，或我们的产品和服务 通过业务合作伙伴的应用程序接口（API）、软件工具开发包（SDK）链接至 业务合作伙伴。 无论何种业务合作伙伴及合作形式，我们会进行严格的安全检
    测接入评估，要求其按照不低于本隐私政策以及适当的保密和安全措施来处理 您的个人信息。 我们与业务合作伙伴共享信息及使用方式有：</p>
  <p>(1) 和灵听功能/服务相关的第三方SDK类业务合作伙伴共享信息及使用方式：</p>
  <p>我们的产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台 上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息
    （如以嵌入代码、插件等形式）。前述服务商收集和处理信息等行为遵守其自身的隐私条款，⽽不适⽤于本政策。
    为了最⼤程度保障您的信息安全，我们建议您在使⽤任何第三⽅SDK类服务前先⾏查看其隐私条款。为保障您的合法
    益，如您发现这等SDK或其他类似的应⽤程序存在⻛险时，建议您⽴即终⽌相关操作并及时与我们取得联系。⽬前我们产品中包含的第三⽅SDK服务明细如下：</p>
  <p>a) 实名认证功能：当您通过第三⽅认证机构（阿⾥云实⼈认证服务、腾讯认证 服务）提供的⼈脸识别服务进⾏实名认证时， 第三⽅认证机构需要获取您设备的相机权限、存储权限并收集姓名、身份证号、⾯部特征值、唯⼀设备识别</p>
  <p>（Android ID、Serial Number）。</p>
  <p>b) ⼿机⼀键登录功能：如您使⽤中国移动提供的本机号码⼀键登录服务，根据 《中国移动认证业务条款》的《⽤户隐私政策》所述，中国移动会收集您的⽹络身份标识（包括注册⼿机号码、注册邮箱、保密邮箱、本机号码）、
    唯⼀设备识别码（Android ID、Serial Number、IMEI、IMSI）、媒体访问控制地址 （MAC地址）、IP地址、应⽤程序数据、设备信息（包括设备型号、设备制造
    商、⽹络类型和wiﬁ强弱情况、⼿机操作系统、屏幕尺⼨、屏幕分辨率）。</p>
  <p>c) 第三⽅帐号登录、分享功能：如您使⽤第三⽅帐号（微信、QQ、新浪微博 帐号）登录，腾讯会收集您的唯⼀设备识别码（Android ID、Serial</p>
  <p>Number） ，新浪微博会获取您设备的存储权限并收集唯⼀设备识别码 （Android ID、Serial Number）。</p>
  <p>d) 消息推送功能：如您使⽤⼩⽶、OPPO、VIVO、华为⼿机时，⼩⽶需要获取 您设备的存储权限并收集唯⼀设备识别码（Android ID）， OPPO/VIVO需要 获取您设备的存储权限并收集您的唯⼀设备识别码（Android
    ID）、系统设置信息，
    华为需要获取您的唯⼀设备识别码（Android ID）， ⽤于推送/下发通知栏消息。</p>
  <p>e) ⽀付功能：如您通过第三⽅⽀付渠道充值虚拟币、购买赠送虚拟物品、开通 会员权益服务，第三⽅⽀付服务提供商（⽀付宝、中国银联、微信⽀付）会获 取您设备的存储权限并收集您的唯⼀设备识别码（Android ID、Serial
    Number）。</p>
  <p>f) 地理位置功能：如您使⽤地理位置相关服务，腾讯地图、⾼德地图需要您打开设备位置权限、存储权限并收集地理位置信息、唯⼀设备识别码（Android ID、Serial Number）。</p>
  <p>(2) 和委托我们进行信息推广与广告投放的授权合作伙伴共享信息及使用方 式：</p>
  <p>您授权我们有权与委托我们进行信息推广和广告投放的合作伙伴共享我们使用 您的相关信息集合形成的无法识别您的个人身份信息的间接用户画像、去标识 化的设备信息（MAC地址、Android ID、Serial
    Number、OAID、IDFA）或匿名化处理后的分析、统计类信息，
    以帮助其在不识别您个人身份的前提下进行广告或决策建议、提高广告有效触达率、衡量广告和相关服务的有效性。但我们不会共享用于识别您个人身份的信息（姓名、身份证号）等。</p>
  <p>4.2 转让</p>
  <p>随着平台业务的发展，灵听或其关联企业有可能进行合并、收购、资产转让或 类似的交易，您的用户信息有可能向第三方分享。
    我们会要求新的持有您信息的公司、组织以不低于本隐私政策的标准继续保护您的个人信息，否则我们将要求该公司、组织重新向您征求授权同意。</p>
  <p>4.3 公开披露：</p>
  <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
  <p>(1) 获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息。</p>
  <p>(2) 如果我们确定您出现违反法律法规或严重违反灵听平台相关服务协议或规 则的情况，或为保护灵听用户或公众的人身财产安全免受侵害，我们可能依据 法律法规或征得您同意的情形情况下披露您的个人信息。</p>
  <p>4.4 共享、转让、公开披露个人信息时事先征得授权同意的例外 在以下情形发生时，分享、披露您的信息无需事先征得您的同意：</p>
  <p>(1) 与我们履行法律法规规定的义务相关的。</p>
  <p>(2) 与国家安全、国防安全直接相关的。</p>
  <p>(3) 与公共安全、公共卫生、重大公共利益直接相关的。</p>
  <p>(4) 与犯罪侦查、起诉、审判和判决执行等直接相关的。</p>
  <p>(5) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难 得到本人授权同意的。</p>
  <p>(6) 所涉及的个人信息是个人信息主体自行向社会公众公开的。</p>
  <p>(7) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息 公开、学术研究成果等渠道。</p>
  <p>(8) 法律法规规定的其他情形。</p>
  <h3 id="t5">5. 您如何了解和修改自己的个人信息</h3>
  <p>我们将尽一切可能采取适当的技术手段，保证您对您个人信息的控制权，包括：您可以访问、更正/修改、删除您的个人信息，也可以撤回之前作出的对
    您个人信息的同意，同时您也可以注销您的灵听帐号。为了方便您行使您的上述控制权，我们在产品的相关功能页面为您提供了操作指引和操作设置，
    您可以自行操作。如您在操作过程中有疑惑或困难，您可以通过本隐私政策第8条 约定的方式联系我们，我们会及时为您处理。在了解、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障帐号安全。
    一般情况下，您可随时修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的某些初始注册信息、验证信息及认证信息。</p>
  <p>5.1 访问个人信息</p>
  <p>5.1.1 进入APP后，您可以在【我的】 - 【编辑资料】，查看/修改您的头像、修 改昵称和性别。</p>
  <p>5.1.2 进入APP后，您可以在【我的】页面分别点击【我的收藏】、【本地】、 【最近播放】、【自建歌单】、【收藏歌单】，查看您的本地音乐或收藏的单曲、歌单、专辑、视频以及最近播放记录等。</p>
  <p>5.2 删除个人信息</p>
  <p>一般而言，我们只会在法律法规规定或必需且最短的时间内保存您的个人信息。为便于您行使您的删除权，我们为您提供了在线自行删除和向我们提出删除申请两种方式。</p>
  <p>5.2.1.对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以直接进行删除。一旦您删除后，我们即会对此类信息进行删除或匿名化处理，除非法律法规另有规定。您可以通过以下方式自主删除您
    的个人信息：</p>
  <p>（1）进入APP后，您可以在【我的】页面分别点击【我的收藏】、【本地】、 【最近播放】、【自建歌单】、【收藏歌单】，删除您的本地音乐或收藏的单曲、歌单、专辑、视频，以及清空最近播放记录等。</p>
  <p>5.2.2 在以下情形下，您可以按照本隐私政策第8条约定的联系方式直接向我们提出删除您个人信息的请求，但已做数据匿名化处理或法律法规另有规定的除外：</p>
  <p>（1）我们违反法律法规规定，收集、使用您的个人信息的；</p>
  <p>（2）我们违反了与您的约定，收集、使用您的个人信息的；</p>
  <p>（3）法律法规等规定的其他情形。</p>
  <p>5.2.3 关于您不再使用我们提供的产品或服务且注销灵听帐号后、以及我们终止部分或全部产品或服务后我们对您的个人信息的处理，我们将在本隐私政策其他专门条款中为您详细说明。</p>
  <p>5.2.4 您理解并同意：当您从我们的产品或服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
  <p>5.2.5 消息设置：</p>
  <p>消息设置需要您在设备设置通知权限进行更改。</p>
  <p>5.4 获取个人信息副本</p>
  <p>如您需要您的个人信息的副本，您可以通过本隐私政策第8条提供的方式联系我们，在核实您的身份后，我们将向您提供您在我们的服务中的个人信息副本（包括 基本资料、身份信息），但法律法规另有规定的或本政策另有约定的除外。</p>
  <p>5.5 投诉举报</p>
  <p>如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以在侧边栏【意见反馈】，进入用户反馈界面与我们联系。也可以通过下述第8条联系方式和我们联系。</p>
  <p>5.7 注销帐号</p>
  <p>
    如您决定不再使用我们的产品及服务，您可以进行注销。在注销您的帐号之前，为保护帐号安全，确保此次注销帐号行为是您本人操作，我们会通过登录密码验证您的身份。我们特别提示，您注销帐号的行为是不可逆的行为，一旦您注销您的帐号，我们将会删除或匿名化有关您帐号的信息。</p>
  <p>5.8 如果我们终止服务或产品运营，我们会至少提前三十日以公告或其他合理、有效的方式向您发出通知，并在终止服务或运营后对您的信息进行删除或 匿名化处理。</p>
  <p>5.9 请您理解并知悉，根据法律法规等规定，在下述情况下，我们可能不会响应您关于查询、更正/修改、删除、撤回同意、注销、个人信息副本获取等的 请求：</p>
  <p>(1) 与我们履行法律法规规定的义务相关的；</p>
  <p>(2) 与国家安全、国防安全直接相关的；</p>
  <p>(3) 与公共安全、公共卫生、重大公共利益直接相关的；</p>
  <p>(4) 与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
  <p>(5) 我们有充分证据表明您存在主观恶意或滥用权利的；</p>
  <p>(6) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人 同意的；</p>
  <p>(7) 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
  <p>(8) 涉及商业秘密的；</p>
  <p>(9) 法律法规等规定的其他情形。</p>
  <h3 id="t6">6. 我们如何存储和保护您的个人信息</h3>
  <p>6.1 存储的地点、期限和方式</p>
  <p>6.1.1 我们遵守法律法规的规定，将境内收集的用户个人信息存储于中国境内。 目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境的目的、接收方、安全保证措施和安全风险，并征得您的同意。</p>
  <p>6.1.2
    一般而言，我们仅为实现目的所必需的最短时间内或法律法规规定的条件下存储您的个人信息，并在超出个人信息保存期限后对您的个人信息进行删除或匿名化处理。但在下列情况下，我们有可能在遵守法律法规规定的前提下，更改个人信息的存储时间：</p>
  <p>(1) 为遵守相关法律法规的规定；</p>
  <p>(2) 为遵守法院判决、裁定或其他法律程序的规定；</p>
  <p>(3) 为遵守相关政府机关或法定授权组织的要求；</p>
  <p>(4) 为执行相关服务协议或本本隐私政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途；</p>
  <p>(5) 其他法律法规规定或您另行授权同意的情形。</p>
  <p>6.1.3
    我们会通过安全技术保护措施存储您的信息，包括本地存储、数据缓存、数据库和服务器日志。当我们的产品或服务发生停止运营的情形时，我们将采取合适的方式（例如推送通知、站内信、公告等形式）通知您，并在合理的期限内删除或匿名化处理您的个人信息。</p>
  <p>6.2 信息安全保障措施</p>
  <p>6.2.1 为保障您的个人信息安全，我们在合理的安全水平内使用各种安全保护措施来保障您的信息，防止数据遭到未经授权访问、公开披露、使用、修改、
    损坏或丢失。例如，我们使用加密技术（如SSL）、匿名化处理等手段来保护您的个人信息。</p>
  <p>6.2.2 我们建立专门的管理制度、审批流程和组织确保信息安全。例如，我们 严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。</p>
  <p>6.2.3 我们鼓励我们的工作人员学习信息安全知识、提高个人信息安全保护意 识，并定期或不定期对我们的工作人员进行信息安全培训。</p>
  <p>6.2.4 若不幸发生个人信息泄露等安全事件，按照法律法规要求，我们会启动 应急预案，阻止安全事件扩大，并及时告知您：安全事件的基本情况和可能的
    影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。
    我们将及时将事件相关情况以邮件、信函、电话、推送通 知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
  <p>6.2.5 互联网环境并非百分之百安全，当出现下列非因我们过错而对您的信息 造成泄露及由此造成的损害结果，我们无需承担任何责任：</p>
  <p>(1) 任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被 篡改等。</p>
  <p>(2) 在使用灵听服务的过程中链接到其它网站或因接受来自第三方的服务所造成之个人资料泄露及由此而导致的任何法律争议和后果。</p>
  <p>(3) 如您在使用灵听服务（例如留言、评论等）的过程中主动公开、上传、发 布或向第三方提供您的个人信息的，其他用户可能会收集您的个人信息。</p>
  <p>(4) 其他用户发布的包含您或您未成年被监护人（子女）的肖像、声音、姓名、 年龄等素材信息的内容。如您不同意我们在灵听产品和服务中展示前述内容，</p>
  <p>请您通过下述第8条联系方式和我们联系。</p>
  <h3 id="t7">7. 我们如何保护未成年人的个人信息</h3>
  <p>7.1 若您是18周岁以下的未成年人，在注册、使用灵听的产品或服务前，应事 先取得父母或监护人的同意，并在父母或监护人指导下使用灵听服务。</p>
  <p>7.2 若您是14周岁以下的儿童，请您通知您的父母或监护人陪同阅读本隐私政策，并在注册、登录、使用灵听的产品或服务以及提交个人信息前征得他们的</p>
  <p>同意和指导。</p>
  <h3 id="t8">8. 本隐私政策如何更新</h3>
  <p>8.1 随着我们的服务范围扩大，我们可能适时更新本隐私政策的条款，更新内 容构成本隐私政策的一部分。如更新后的隐私政策导致您的权利发生实质改变，我们将在更新前通过显著位置提示或以推送消息、红点提示等方式通知您，
    您继续使用我们的服务，即表示同意受更新 后的隐私政策约束。为避免您不能及时获知更新，请您经常阅读本隐私政策。您可以在“【侧边栏】 - 【关 于我们】 - 【隐私政策】”中查看更新后的《隐私政策》。</p>
  <p>8.2 更新后的《隐私政策》将以更新日期为生效日期，并取代之前的《隐私政策》。</p>
  <h3 id="t9">9. 如何与我们联系</h3>
  <p>广州市禾唱网络技术有限责任公司将按照本政策保护您的个人信息。
    如您有关于网络信息安全的投诉和举报，或您对本隐私政策有问题、意见、建议，或者与用户个人信息安全相关的投诉、举报，您可以通过电子邮件的方式（邮箱：HeChangGZ@163.com）与我们进行联系，我们将在收到邮件并验证您的用户身份后十五日内予以回复。</p>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
</style>
