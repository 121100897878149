<template>
  <div id="app">
    <div class="banner">
      <h1 class="banner-title">您的声音，我们倾听</h1>
    </div>
    <div class="feedback-form">
      <div class="options">
        <button
            v-for="(option, index) in options"
            :key="index"
            @click="selectedOption = option"
            :class="{ active: selectedOption === option }"
        >
          {{ option }}
        </button>
      </div>
      <div class="textarea-input-container" >
       <textarea
           v-model="message"
           placeholder="请详细描述您的意见或建议..."
           rows="5"
       ></textarea>
        <input
            v-model="contactInfo"
            type="text"
            placeholder="请输入您的联系方式（邮箱或电话）"
        >
      </div>
      <button @click="submitFeedback">提交反馈</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedbackPage",
  data() {
    return {
      options: ['提建议', '提缺陷', '我想要'],
      selectedOption: '',
      message: '',
      contactInfo: '' // 新增的数据属性用于存储联系方式
    };
  },
  methods: {
    submitFeedback() {
      if (this.selectedOption && this.message && this.contactInfo) {
        console.log('提交的反馈:', {
          type: this.selectedOption,
          content: this.message,
          contact: this.contactInfo
        });
        // 这里可以添加API调用逻辑以发送数据到服务器
        alert('您的反馈已提交，感谢您的宝贵意见！');
        this.message = '';
        this.contactInfo = ''; // 清空输入框
      } else {
        alert('请完整填写反馈类型、内容及联系方式！');
      }
    }
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
}

#app {
  /* 确保#app没有影响到子元素的外边距和内边距 */
  margin: 0;
  padding: 0;

  /* 如果有设置边框，确保它不会影响到内部元素的布局 */
  border: none;

  /* 或者如果需要边框，确保边框的计算方式不影响内部尺寸 */
  box-sizing: border-box;
}
.feedback-form {
  text-align: center;
  margin-top: 50px;
}

.options button {
  background-color: #f1f1f1;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

.options button.active {
  background-color: #4CAF50; /* 绿色 */
  color: white;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}


textarea {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 10px;
  box-sizing: border-box;
  resize: vertical;
}


.textarea-input-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

textarea,
input[type="text"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.banner {
  position: relative;
  width: 100%;
  height: 200px; /* 根据需要调整高度 */
  background-image: url('@/assets/banner-image.jpg'); /* 图片路径 */
  background-size: cover; /* 让背景图片覆盖整个容器 */
  background-position: center; /* 图片居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* 确保标题颜色与背景对比度足够 */
  margin: 0; /* 防止外部margin影响 */
  padding: 0; /* 移除内部padding */
}

.banner-title {
  position: absolute; /* 使用绝对定位 */
  right: 60%;
  color: black; /* 更改字体颜色为黑色 */
  text-align: left; /* 文字左对齐 */
  font-size: 2rem; /* 保持之前的字体大小调整 */
}
</style>
