<script setup>

</script>

<template>
  <body>
    <main>
      <div style="display: flex; width: 100%; align-self: center">
        <div style="flex: 1;"></div>
        <img src="../assets/songtellnet.jpg" style="width: 100px; height: 100px; margin-right: 20px"/>
        <div>
          <h1 style="color: black">
            歌词AI鉴赏
          </h1>
          <br>
          <p style="color: #4f4f4f">
            用AI解锁歌曲背后的深度含义，<br>
            发现音乐的美妙和感动
          </p>
        </div>
        <div style="flex: 1;"></div>
      </div>
      <br>
      <br>
      <br>
      <p class="text">
        Songtell.NET 的 AI 系统是由一群音乐爱好者和 AI 专家共同打造而成的。我们深知音乐的力量，也深知 AI 技术的优势。因此，我们将这两者结合起来，打造了一个能够帮助大家更好地理解音乐的平台。我们的系统已经解锁了数千首歌曲的深度含义，每天还在不断更新中。
      </p>
    </main>
    <footer>
      <span >
            <a href="https://beian.miit.gov.cn" target="_blank" style="color: rgba(0,0,0,0.5)">备案号：粤ICP备2023133124号-1</a>
          </span>
      <span style="color: rgba(0,0,0,0.5)">
            Copyright© 2023 广州市禾唱网络技术有限责任公司 All Rights Reserved
        </span>
    </footer>
  </body>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #e1fde1;
  position: fixed;
  top: 0;
  bottom:0;
  left:0;
  width: 100%;
  height: 100%;
}

.text {
  color: black;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
}



main {
  height: 100vh;
  padding-top: 18vh;
  overflow-y: auto;
}
footer {
  left: 0;
  right: 0;
  height: 58px;
  background-color: rgba(204, 204, 204, 0.1);
  color: #ccc;
  display: block;
  text-align: center;
  line-height: 56px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer span:first-of-type {
  margin-right: 30px;
}

@media (max-width: 992px) {
  main {
    padding-top: 15vh;
  }

  .text {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 768px) {
  footer {
    display: block;
    height: 58px;
    padding-top: 8px;
  }

  footer span {
    font-size: 12px;
    display: block;
    text-align: center;
    line-height: 16px;
  }

  footer span:first-of-type {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  body {
    background-color: black;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>