<script>

</script>

<template>
  <body>
  <main>
    <img src="../assets/image_two.webp" class="img"/>

 
    <p class="text" style="text-align: center;">
      FFmpeg 是领先的多媒体框架，能够解码、编码、转码、复用、分离、流式传输、过滤和播放几乎所有人类和机器创造的内容。它支持从最古老的格式到前沿技术的各种格式。无论这些格式是由某个标准委员会、社区还是公司设计的。FFmpeg 也具有高度的可移植性：它在 Linux、Mac OS X、Microsoft Windows、BSDs、Solaris 等系统上编译、运行，并且通过了我们的测试基础设施 FATE，在各种构建环境、机器架构和配置下运行良好。
    </p>


  </main>

  <footer>
           <span >
            <a href="https://beian.miit.gov.cn" target="_blank" style="color: lightgray">备案号：粤ICP备2023133124号-1</a>
          </span>
    <span>
              Copyright© 2023 广州市禾唱网络技术有限责任公司 All Rights Reserved
          </span>
  </footer>
  </body>
</template>

<style scoped>

.text {
  color: white;
  width: 100%;
  text-align: center;
  padding-right: 20%;
  padding-left: 20%;
}
.img {
  width: 40%;
  height: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  position: fixed;
  top: 0;
  bottom:0;
  left:0;
  width: 100%;
  height: 100%;
}

main {
  height: 100vh;
  padding-top: 10vh;
  overflow-y: auto;
}

footer {
  left: 0;
  right: 0;
  height: 58px;
  background-color: rgba(204, 204, 204, 0.1);
  color: #ccc;
  display: block;
  text-align: center;
  line-height: 56px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer span:first-of-type {
  margin-right: 30px;
}

@media (max-width: 992px) {
  main {
    padding-top: 8vh;
  }

  .text {
    padding-right: 5%;
    padding-left: 5%;
  }
  .img {
    width: 85%;
    height: auto;
  }

  .app-info-wrapper {
    display: none;
  }

  .app-info-wrapper-mobile {
    display: block;
    width: 90%;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .app-logo {
    width: 77px;
    display: block;
    margin-bottom: 14px;
  }

  .app-name {
    font-size: 23px;
    font-weight: bolder;
    text-align: center;
  }

  .download-btn {
    padding: 8px 12px;
    border-radius: 8px;
  }

  .android-logo {
    height: 30px;
    width: 28px;
  }

  footer {
    display: block;
    height: 58px;
    padding-top: 8px;
  }

  footer span {
    font-size: 12px;
    display: block;
    text-align: center;
    line-height: 16px;
  }

  footer span:first-of-type {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  body {
    background-color: black;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>