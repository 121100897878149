<script setup>

let selected = 0;
import {useRouter} from "vue-router";

const router = useRouter()

function show_second(){
  router.push({
    path: '/second',
  })
}
function show_first(){
  router.push({
    path: '/first',
  })
}

function show_main(){
  router.push({
    path: '/home',
  })
}
</script>

<template>
  <router-link to="/home">MainPage</router-link>
  <router-link to="/first">FirstPage</router-link>
  <router-link to="/second">SecondPage</router-link>
  <router-view></router-view>
  <body class="page-body black">
  <div class="topnav">
    <a class="active" style="" v-show="selected === 0" href="" @click="show_main">主页</a>
    <a href="" @click="show_first">AI音乐分析工具</a>
    <a href="" @click="show_second">分类实例</a>

  </div>
  </body>

</template>

<style scoped>
body {
  margin: 0;
  background-repeat: no-repeat;
  font-family: Arial, Helvetica, sans-serif;
}

@media (max-width: 576px) {
  body {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.topnav {
  overflow: hidden;
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  background-color: #303030;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #FF0000;
  color: white;
}
/* Float four columns side by side */
.column {
  float: left;
  width: 20%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 60px  15px 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}
.card:hover{
  background-color:#CCC;
}
</style>